import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()
const app=createApp(App)
// axios.defaults.baseURL = 'http://localhost:4000';
// app.config.globalProperties.baseURL = "http://localhost:4000";
// app.config.globalProperties.mediaURL = "http://localhost:4000/wp/"

axios.defaults.baseURL = 'https://api.trinity4education.com';
app.config.globalProperties.baseURL = "https://api.trinity4education.com";
app.config.globalProperties.mediaURL = "https://api.trinity4education.com/wp/"
createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
