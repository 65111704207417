import { createStore } from 'vuex'
 import router from '@/router';
 import axios from 'axios'

export default createStore({
  state: {
    isLoggedIn: !!localStorage.getItem("token"),
    // courses: [],
  },
  getters: {
  },
  mutations: {
    loginUser(state, payload) {
      localStorage.setItem("token", payload);
      state.isLoggedIn = true
  },

  logoutUser(state) {
    axios({
        method: "POST",
        url: "/user/logout/token",
        headers: {
            "token": localStorage.getItem("token")
        }
    }).then(response => {
        if (response.data) {
            state.isLoggedIn = false
            state.currentPage = null
            localStorage.clear();
            // localStorage.removeItem("token");
            router.push('/')
        }
    })
},
  

  },
  actions: {
  },
  modules: {
  }
})
